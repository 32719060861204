<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="6">
        <el-input
            placeholder="请输入openid"
            v-model="pagination.openid"
            clearable>
        </el-input>
      </el-col>
      <el-col :span="6">
        <el-input
            placeholder="请输入手机号"
            v-model="pagination.phone"
            clearable>
        </el-input>
      </el-col>
      <el-col :span="6">
        <el-input
            placeholder="请输入昵称"
            v-model="pagination.nickname"
            clearable>
        </el-input>
      </el-col>
      <el-col :span="6">
        <el-button type="primary" style="margin-bottom: 15px;" class="el-icon-eleme" @click="sendRequest">
          查询
        </el-button>
      </el-col>
    </el-row>
    <el-table
        v-loading="loading"
        :data="tableData"
        style="width: 100%"
        :size="tableSize"
        :max-height="maxHeight"
        :row-class-name="tableRowClassName"
        :header-cell-style="{background:'#eef1f6',color:'#606266'}">
      <el-table-column
          fixed
          prop="openid"
          label="openid"
          min-width="180">
      </el-table-column>
      <el-table-column
          prop="nickname"
          label="昵称"
          min-width="100">
      </el-table-column>
      <el-table-column
          prop="avatarUrl"
          label="头像"
          min-width="80">
        <template slot-scope="scope">
          <img :src="scope.row.avatarUrl" min-width="40" height="40"/>
        </template>
      </el-table-column>
      <el-table-column
          prop="belongType"
          label="归属类型"
          min-width="120">
      </el-table-column>
      <el-table-column
          prop="phone"
          label="手机号"
          min-width="120">
      </el-table-column>
      <el-table-column
          prop="createTime"
          label="申请时间"
          min-width="180">
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          min-width ="120">
        <template slot-scope="scope">
          <el-tooltip content="编辑" placement="left-start" effect="light">
            <el-button
                @click.native.prevent="editRow(scope.$index, tableData)"
                icon="el-icon-edit"
                size="mini"
                type="primary">
            </el-button>
          </el-tooltip>
          <el-tooltip content="删除" placement="top-end" effect="light">
            <el-button
                @click.native.prevent="deleteRow(scope.$index, tableData)"
                icon="el-icon-delete"
                size="mini"
                type="danger">
            </el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination" style="text-align: right;">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 15, 20]"
          :page-size="pagination.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pagination.total">
      </el-pagination>
    </div>
    <!-- 弹出框form -->
    <el-dialog title="openid信息"
               :visible.sync="dialogFormVisible"
               @close="closeDialog"
               :show-close="false"
               :close-on-press-escape="false"
               fullscreen
               center >
      <el-form :inline="true" :size="formSize" label-position="right">
        <el-row>
          <el-col :span="24">
            <el-form-item label="头像" :size="formSize" :label-width="formLabelWidth">
              <img :src="editForm.avatarUrl" min-width="40" height="40"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="昵称" :size="formSize" :label-width="formLabelWidth">
              <el-input readonly v-model="editForm.nickname" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="openid" :size="formSize" :label-width="formLabelWidth">
              <el-input readonly v-model="editForm.openid" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="手机号" :size="formSize" :label-width="formLabelWidth">
              <el-input readonly v-model="editForm.phone" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="归属类型" :size="formSize" :label-width="formLabelWidth">
              <el-select v-model="editForm.belongType" placeholder="请选择" :size="formSize"
                         @change="changeBelongType">
                <el-option
                    v-for="item in belongTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="editForm.belongType == '1'">
          <el-col :span="24">
            <el-form-item label="分公司" :size="formSize" :label-width="formLabelWidth">
              <el-select v-model="branchSelVal" placeholder="请选择" :size="formSize">
                <el-option
                    v-for="item in belongList"
                    :key="item.id"
                    :label="item.alias2"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-show="editForm.belongType == '2'" style="margin-top: 15px;">
          <el-col :span="24">
            <div style="margin-left: 15px;">
              <h4>选择网点</h4>
              <el-input
                  v-model="search"
                  size="mini"
                  style="margin-bottom: 5px;width: 200px;"
                  placeholder="输入关键字搜索"/>
              <el-badge :value="selSiteList.length" class="item" style="float: right;">
                <el-button size="small" @click="openInnerDialog">查看已选网点</el-button>
              </el-badge>
              <el-table ref="siteTable"
                        :data="siteList"
                        @selection-change="handleSelectionChange"
                        :row-key="getRowKeys"
                        height="300"
                        size="mini"
                        border>
                <el-table-column type="selection" width="55" :reserve-selection="true"></el-table-column>
                <el-table-column property="websitNumber" label="网点编号" width="100"></el-table-column>
                <el-table-column property="name" label="网点名称"></el-table-column>
                <el-table-column property="type" label="网点类别" width="100"></el-table-column>
                <el-table-column property="address" label="网点地址"></el-table-column>
              </el-table>
              <div class="pagination" style="text-align: right;">
                <el-pagination
                    @size-change="dialogHandleSizeChange"
                    @current-change="dialogHandleCurrentChange"
                    :page-sizes="[50]"
                    :page-size="dialogPagination.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="dialogPagination.total">
                </el-pagination>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row v-show="editForm.belongType == '3'" style="margin-top: 15px;">
          <el-col :span="24">
            <div style="margin-left: 15px; text-align: center;">
              <h4>选择销售区域</h4>
<!--              <el-table ref="saleAreaTable"-->
<!--                        :data="saleAreaList"-->
<!--                        height="300"-->
<!--                        size="mini"-->
<!--                        border>-->
<!--                <el-table-column type="selection" width="55"></el-table-column>-->
<!--                <el-table-column property="belongCompanyName" label="销司名称"></el-table-column>-->
<!--                <el-table-column property="belongArea" label="区域名称"></el-table-column>-->
<!--                <el-table-column property="remark" label="备注"></el-table-column>-->
<!--              </el-table>-->
              <el-transfer
                  v-model="selSaleAreaList"
                  :data="saleAreaList"
                  :titles="['未选', '已选']"
                  style="text-align: left; display: inline-block">
              </el-transfer>
            </div>
          </el-col>
        </el-row>
        <el-row style="margin-top: 15px;">
          <el-col :span="24">
            <div style="margin-left: 15px;">
              <h4>选择角色</h4>
              <el-table :data="roleData"
                        max-height="300"
                        size="mini"
                        ref="roleTable"
                        border>
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column property="id" label="角色序号"></el-table-column>
                <el-table-column property="name" label="角色名称"></el-table-column>
              </el-table>
            </div>
          </el-col>
        </el-row>
        <el-form-item>
        </el-form-item>
      </el-form>
      <el-dialog
          width="50%"
          title="已选网点信息"
          :visible.sync="innerVisible"
          append-to-body>
        <el-table
            :data="selSiteList"
            height="250"
            size="mini"
            border
            style="width: 100%">
          <el-table-column property="websitNumber" label="网点编号" width="100"></el-table-column>
          <el-table-column property="name" label="网点名称"></el-table-column>
          <el-table-column
              label="操作"
              width ="70">
            <template slot-scope="scope">
              <el-button
                  @click.native.prevent="deleteSelSiteRow(scope.$index, selSiteList)"
                  icon="el-icon-delete"
                  size="mini"
                  type="danger">
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-dialog>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogCancel">取 消</el-button>
        <el-button type="primary" @click="confirmFrom()" v-prevent-re-click>确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "AuthorizeMobileOffice",
  data() {
    return {
      loading: true,
      tableSize: 'medium',
      maxHeight: 500,
      rowHeight: 36,
      branchSelVal: '',
      tableData: [],
      pagination: {
        openid: '',
        phone: '',
        nickname: '',
        pageSize: 10,
        pageNum: 1,
        total: 0
      },
      dialogPagination: {
        pageSize: 50,
        pageNum: 1,
        total: 0
      },
      belongTypeList: [
        {value: '0', label: '总部'},
        {value: '1', label: '分公司'},
        {value: '2', label: '网点'},
        {value: '3', label: '销售区域'},
      ],
      belongList: [],
      originalSiteList: [],
      siteList: [],
      selSiteList: [],
      saleAreaList: [],
      selSaleAreaList: [],
      editForm: {
        id: '',
        name: '',
        belongType: '',
        baseBelongType: '',
        roleList: [],
        siteList:[]
      },
      roleData: [],
      formLabelWidth: '75px',
      formSize: 'small',
      dialogFormVisible: false,
      innerVisible: false,
      search: ''
    }
  },
  watch: {
    'search': function (newVal, oldVal) {
      let pageNum = 1;
      let pageSize = this.dialogPagination.pageSize;
      let data = this.originalSiteList.filter(data => !this.search || data.name.includes(this.search)
          || data.type.toLowerCase().includes(this.search.toLowerCase())
          || data.address.includes(this.search)
          || data.websitNumber.toLowerCase().includes(this.search.toLowerCase()));
      this.dialogPagination.total = data.length;
      this.siteList = data.slice((pageNum-1)*pageSize, pageNum*pageSize);
    }
  },
  mounted() {
    //vue加载完成后发送列表请求
    this.sendRequest();
    //查询分公司请求
    this.getRequest('/common-query/mobile/belong').then(resp => {
      if(resp) {
        this.belongList = resp;
      }
    });
    //查询销售区域
    this.getRequest('/common-query/mobile/sale-area').then(resp => {
      if(resp) {
        resp.forEach(value => {
          this.saleAreaList.push({
            label: value.belongArea,
            key: value.id
          });
        })
      }
    });
    //vuex查询网点
    this.originalSiteList = this.$store.state.sites;
  },
  methods: {
    sendRequest() {
      this.loading = true;
      let params = {
        openid: this.pagination.openid,
        phone: this.pagination.phone,
        nickname: this.pagination.nickname,
        pageSize: this.pagination.pageSize,
        pageNum: this.pagination.pageNum
      }
      this.getRequest('/authorize/mobile-office/', params).then((resp)=> {
        if(resp) {
          this.tableData = resp.data.records;
          this.pagination.total = resp.data.total;
        }
        this.loading = false;
      }, () => {this.loading = false;})
    },
    confirmFrom() {
      //归属类型处理
      this.editForm.siteList = [];
      this.editForm.roleList = [];
      if(this.editForm.belongType == '1' && this.branchSelVal.length == 0) {
        this.$message.warning("请选择分公司");
        return;
      } else if(this.editForm.belongType == '2' && this.$refs.siteTable.selection
          && this.$refs.siteTable.selection.length == 0) {
        this.$message.warning("请选择一个或多个网点");
        return;
      }
      if(this.editForm.belongType == '1') {
        //注入分公司
        this.editForm.siteList.push({"siteId" : this.branchSelVal, "websitNumber": ""});
      } else if(this.editForm.belongType == '2') {
        //注入网点
        if(this.$refs.siteTable.selection && this.$refs.siteTable.selection.length > 0) {
          this.$refs.siteTable.selection.forEach(value => {
            this.editForm.siteList.push({"siteId" : value.id, "websitNumber": value.websitNumber});
          })
        }
      } else if(this.editForm.belongType == '3') {
        //注入销售区域
        if(this.selSaleAreaList && this.selSaleAreaList.length > 0) {
          this.selSaleAreaList.forEach(value => {
            this.editForm.siteList.push({"siteId" : value, "websitNumber": value});
          })
        }
      }
      //角色处理
      if(this.$refs.roleTable.selection && this.$refs.roleTable.selection.length > 0) {
        this.$refs.roleTable.selection.forEach(value => {
          this.editForm.roleList.push({"mrId": value.id});
        })
      }
      if (this.editForm.roleList.length <= 0) {
        this.$message.warning("请选择一个角色");
        return;
      }

      this.putRequest('/authorize/mobile-office/', this.editForm).then(resp => {
        if(resp) {
          //清空弹框数据并重新查询列表
          this.dialogCancel();
          setTimeout(() => {
            this.sendRequest();
          }, 1000)
        }
      })
    },
    editRow(index, rows) {
      //点击编辑按钮触发事件
      this.siteList = [];
      this.dialogFormVisible = true;
      this.loading = true;
      let row = rows[index];
      //查询所有角色
      this.getRequest('/common-query/mobile/role').then(resp => {
        if(resp) {
          this.roleData = resp;
          this.loading = false;
          //查询当前点击的openid信息
          this.getRequest('/authorize/mobile-office/info/' + row.id).then(resp => {
            this.editForm = resp.data;
            this.siteList = this.originalSiteList.slice((this.dialogPagination.pageNum-1)*this.dialogPagination.pageSize, this.dialogPagination.pageNum*this.dialogPagination.pageSize);
            this.dialogPagination.total = this.originalSiteList.length;
            //初始化主体内容
            this.editForm.belongType = this.editForm.baseBelongType;
            //角色的回显处理
            if(this.roleData && this.roleData.length > 0
                && this.editForm.roleList && this.editForm.roleList.length > 0) {
              this.roleData.forEach((value, index, array) => {
                this.editForm.roleList.find((role, i, o) => {
                  if(value.id == role.mrId) {
                    this.$refs.roleTable.toggleRowSelection(value,true);
                  }
                })
              })
            };

            if(this.editForm.baseBelongType == '1') {
              //分公司回显
              this.branchSelVal = this.editForm.siteList[0].siteId;
            } else if(this.editForm.baseBelongType == '2' || this.editForm.baseBelongType == '3') {
              //网点回显
              let selSiteList = this.editForm.siteList;
              if(selSiteList && selSiteList.length > 0) {
                if(this.editForm.baseBelongType == '2') {
                  this.originalSiteList.forEach((value, index, array) => {
                    selSiteList.find((s, i, o) => {
                      if(s.siteId == value.id) {
                        //暂存历史选中
                        this.$refs.siteTable.toggleRowSelection(value,true);
                      }
                    })
                  })
                } else {
                  selSiteList.forEach(value => {
                    this.selSaleAreaList.push(value.siteId)
                  })
                }
              }
            }
          });
        }
      })
    },
    deleteRow(index, rows) {
      //删除当前openid
      let row = rows[index];
      let promptMessages = ['确认删除信息:', `openid: ${row.openid}`, `昵称: ${row.nickname}`, `手机号: ${row.phone}`]
      const newPromptMessages = []
      const h = this.$createElement
      for (const i in promptMessages) {
        newPromptMessages.push(h('p', null, promptMessages[i]))
      }
      //删除openid提示
      this.$confirm('提示', {
        message: h('div', null, newPromptMessages),
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true,
        type: 'warning'
      }).then(() => {
        //发送删除请求
        this.deleteRequest('/authorize/mobile-office/' + row.id).then(resp => {
          this.sendRequest();
        });
      }).catch(() => {
        console.log('取消删除')
      })
    },
    changeBelongType(selValue) {
    },
    handleSizeChange(val) {
      this.pagination.pageSize = val;
      this.sendRequest();
    },
    handleCurrentChange(val) {
      this.pagination.pageNum = val;
      this.sendRequest();
    },
    dialogHandleSizeChange(val) {
      this.dialogPagination.pageSize = val;
    },
    dialogHandleCurrentChange(val) {
      this.dialogPagination.pageNum = val;
      if(this.search.length == 0) {
        this.siteList = this.originalSiteList.slice((this.dialogPagination.pageNum-1)*this.dialogPagination.pageSize, this.dialogPagination.pageNum*this.dialogPagination.pageSize);
        this.dialogPagination.total = this.originalSiteList.length;
      } else {
        let pageNum = this.dialogPagination.pageNum;
        let pageSize = this.dialogPagination.pageSize;
        let data = this.originalSiteList.filter(data => !this.search || data.name.includes(this.search)
            || data.type.toLowerCase().includes(this.search.toLowerCase())
            || data.address.includes(this.search)
            || data.websitNumber.toLowerCase().includes(this.search.toLowerCase()));
        this.dialogPagination.total = data.length;
        this.siteList = data.slice((pageNum-1)*pageSize, pageNum*pageSize);
      }
    },
    tableRowClassName({row, rowIndex}) {
      if (rowIndex % 2 === 1) {
        return 'row-bg-color';
      } else {
        return '';
      }
    },
    clearForm() {
      //清空提交表单内容
      this.editForm.id = '';
      this.editForm.name = '';
      this.editForm.belongType = '';
      this.editForm.baseBelongType = '';
      this.editForm.roleList = [];
      this.editForm.siteList = [];
      this.branchSelVal = '';
      this.selSiteList = [];
      this.siteList = [];
      this.selSaleAreaList = [];
      this.search = '';
      this.$refs.siteTable.clearSelection();
      this.dialogHandleCurrentChange(1);
      this.dialogPagination.total = 0;
    },
    dialogCancel() {
      //点击取消按钮事件
      this.clearForm();
      this.dialogFormVisible = false;
    },
    closeDialog() {
      //关闭弹框时事件
      this.dialogCancel();
    },
    openInnerDialog() {
      this.innerVisible = true;
    },
    getRowKeys(row) {
      return row.id;
    },
    handleSelectionChange(val) {
      this.selSiteList = val;//勾选放在selSiteList数组中
    },
    deleteSelSiteRow(index, rows) {
      this.$refs.siteTable.toggleRowSelection(this.selSiteList[index], false);
    },
  }
}
</script>

<style>
.el-table .row-bg-color {
  background: #eef1f6;
}
</style>
