<template>
  <div>
    <el-table
        :data="tableData"
        style="width: 100%"
        :size="tableSize"
        :max-height="maxHeight"
        :row-class-name="tableRowClassName"
        :header-cell-style="{background:'#eef1f6',color:'#606266'}">
      <el-table-column
          fixed
          prop="openid"
          label="openid"
          min-width="180">
      </el-table-column>
      <el-table-column
          prop="nickname"
          label="昵称"
          min-width="100">
      </el-table-column>
      <el-table-column
          prop="avatarUrl"
          label="头像"
          min-width="80">
        <template slot-scope="scope">
          <img :src="scope.row.avatarUrl" min-width="40" height="40"/>
        </template>
      </el-table-column>
      <el-table-column
          prop="belongType"
          label="归属类型"
          min-width="120">
      </el-table-column>
      <el-table-column
          prop="phone"
          label="手机号"
          min-width="120">
      </el-table-column>
      <el-table-column
          prop="createTime"
          label="申请时间"
          min-width="180">
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          min-width ="120">
        <template slot-scope="scope">
          <el-tooltip content="编辑" placement="left-start" effect="light">
            <el-button
                @click.native.prevent="editRow(scope.$index, tableData)"
                icon="el-icon-edit"
                size="mini"
                type="primary">
            </el-button>
          </el-tooltip>
          <el-tooltip content="删除" placement="top-end" effect="light">
            <el-button
                @click.native.prevent="deleteRow(scope.$index, tableData)"
                icon="el-icon-delete"
                size="mini"
                type="danger">
            </el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination" style="text-align: right;">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 15, 20]"
          :page-size="pagination.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pagination.total">
      </el-pagination>
    </div>
    <!-- 弹出框form -->
    <el-dialog title="openid信息" :visible.sync="dialogFormVisible" @close="closeDialog" fullscreen center>
      <el-form :inline="true" :size="formSize" label-position="right">
        <el-row>
          <el-col :span="24">
            <el-form-item label="头像" :size="formSize" :label-width="formLabelWidth">
              <img :src="editForm.avatarUrl" min-width="40" height="40"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="昵称" :size="formSize" :label-width="formLabelWidth">
              <el-input readonly v-model="editForm.nickname" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="openid" :size="formSize" :label-width="formLabelWidth">
              <el-input readonly v-model="editForm.openid" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="手机号" :size="formSize" :label-width="formLabelWidth">
              <el-input readonly v-model="editForm.phone" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="归属类型" :size="formSize" :label-width="formLabelWidth">
              <el-select v-model="editForm.belongType" placeholder="请选择" :size="formSize"
                         @change="changeBelongType">
                <el-option
                    v-for="item in belongTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="editForm.belongType == 'BRANCH'">
          <el-col :span="24">
            <el-form-item label="分公司" :size="formSize" :label-width="formLabelWidth">
              <el-select v-model="branchSelVal" placeholder="请选择" :size="formSize">
                <el-option
                    v-for="item in belongList"
                    :key="item.id"
                    :label="item.alias2"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-show="editForm.belongType == 'SITE'" style="margin-top: 15px;">
          <el-col :span="24">
            <div style="margin-left: 15px;">
              <h4>选择网点</h4>
              <el-input
                  v-model="search"
                  size="mini"
                  style="margin-bottom: 5px;width: 200px;"
                  placeholder="输入关键字搜索"/>
              <el-badge :value="selSiteList.length" class="item" style="float: right;">
                <el-button size="small" @click="openInnerDialog">查看已选网点</el-button>
              </el-badge>
              <el-table ref="siteTable"
                        :data="handleDialogPagination"
                        @selection-change="siteSelChange"
                        height="300"
                        size="mini"
                        border>
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column property="websitNumber" label="网点编号" width="100"></el-table-column>
                <el-table-column property="name" label="网点名称"></el-table-column>
                <el-table-column property="type" label="网点类别" width="100"></el-table-column>
                <el-table-column property="address" label="网点地址"></el-table-column>
              </el-table>
              <div class="pagination" style="text-align: right;">
                <el-pagination
                    @size-change="dialogHandleSizeChange"
                    @current-change="dialogHandleCurrentChange"
                    :page-sizes="[50]"
                    :page-size="dialogPagination.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="dialogPagination.total">
                </el-pagination>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row style="margin-top: 15px;">
          <el-col :span="24">
            <div style="margin-left: 15px;">
              <h4>选择角色</h4>
              <el-table :data="roleData"
                        max-height="300"
                        size="mini"
                        :loading="loading"
                        element-loading-text="加载中"
                        element-loading-spinner="el-icon-loading"
                        element-loading-background="rgba(0, 0, 0, 0.8)"
                        ref="roleTable">
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column property="id" label="角色序号"></el-table-column>
                <el-table-column property="name" label="角色名称"></el-table-column>
              </el-table>
            </div>
          </el-col>
        </el-row>
        <el-form-item>
        </el-form-item>
      </el-form>
      <el-dialog
          width="50%"
          title="已选网点信息"
          :visible.sync="innerVisible"
          append-to-body>
        <el-table
            :data="selSiteList"
            height="250"
            size="mini"
            border
            style="width: 100%">
          <el-table-column property="websitNumber" label="网点编号" width="100"></el-table-column>
          <el-table-column property="name" label="网点名称"></el-table-column>
          <el-table-column
              label="操作"
              width ="70">
            <template slot-scope="scope">
              <el-button
                  @click.native.prevent="deleteSelSiteRow(scope.$index, selSiteList)"
                  icon="el-icon-delete"
                  size="mini"
                  type="danger">
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-dialog>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogCancel">取 消</el-button>
        <el-button type="primary" @click="confirmFrom()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "AuthorizeMobileOffice",
  methods: {
    sendRequest() {
      this.getRequest('/authorize/mobile-office/?pageSize=' + this.pagination.pageSize
          + '&pageNum=' + this.pagination.pageNum).then((resp)=> {
        if(resp) {
          this.tableData = resp.data.records;
          this.pagination.total = resp.data.total;
        }
      })
    },
    confirmFrom() {
      //归属类型处理
      this.editForm.siteList = [];
      this.editForm.roleList = [];
      if(this.editForm.belongType == 'BRANCH' && this.branchSelVal.length == 0) {
        this.$message.warning("请选择分公司");
        return;
      } else if(this.editForm.belongType == 'SITE' && this.selSiteList.length == 0) {
        this.$message.warning("请选择一个或多个网点");
        return;
      }
      if(this.editForm.belongType == 'BRANCH') {
        this.editForm.siteList.push({"siteId" : this.branchSelVal});
      } else if(this.editForm.belongType == 'SITE') {
        this.selSiteList.forEach(value => {
          this.editForm.siteList.push({"siteId" : value.id});
        })
      }
      //角色处理
      if(this.$refs.roleTable.selection && this.$refs.roleTable.selection.length > 0) {
        this.$refs.roleTable.selection.forEach(value => {
          this.editForm.roleList.push({"mrId": value.id});
        })
      }
      console.log(JSON.stringify(this.editForm));
      this.putRequest('/authorize/mobile-office/', this.editForm).then(resp => {
        if(resp) {
          this.dialogFormVisible = false;
          this.sendRequest();
        }
      })
    },
    editRow(index, rows) {
      //点击编辑按钮触发事件
      this.loading = true;
      let row = rows[index];
      this.dialogFormVisible = true;
      //查询所有角色
      this.getRequest('/common-query/mobile/role').then(resp => {
        if(resp) {
          this.roleData = resp;
          this.loading = false;
          //查询当前点击的openid信息
          this.getRequest('/authorize/mobile-office/info/' + row.id).then(resp => {
            this.editForm = resp.data;
            //判断当前openid的所属类型
            if(this.editForm.baseBelongType == '0') {
              this.editForm.belongType = 'HEAD';
            } else if(this.editForm.baseBelongType == '1') {
              this.editForm.belongType = 'BRANCH';
            } else {
              this.editForm.belongType = 'SITE';
            }
            //角色的回显处理
            if(this.roleData && this.roleData.length > 0
                && this.editForm.roleList && this.editForm.roleList.length > 0) {
              this.roleData.forEach((value, index, array) => {
                this.editForm.roleList.find((role, i, o) => {
                  if(value.id == role.mrId) {
                    this.$refs.roleTable.toggleRowSelection(value,true);
                  }
                })
              })
            };
            //分公司回显
            if(this.editForm.baseBelongType == '1') {
              this.branchSelVal = this.editForm.siteList[0].siteId;
            }
            //网点回显
            if(this.editForm.baseBelongType == '2') {
              let selSiteList = this.editForm.siteList;
              if(selSiteList && selSiteList.length > 0) {
                this.siteList.forEach((value, index, array) => {
                  selSiteList.find((s, i, o) => {
                    if(s.siteId == value.id) {
                      //暂存历史选中
                      this.selSiteList.push(value);
                      // this.$refs.siteTable.toggleRowSelection(value,true);
                    }
                  })
                })
              }
            }
          });
        }
      })
    },
    deleteRow(index, rows) {
      //删除当前openid
      let row = rows[index];
      let promptMessages = ['确认删除信息:', `openid: ${row.openid}`, `昵称: ${row.nickname}`, `手机号: ${row.phone}`]
      const newPromptMessages = []
      const h = this.$createElement
      for (const i in promptMessages) {
        newPromptMessages.push(h('p', null, promptMessages[i]))
      }
      //删除openid提示
      this.$confirm('提示', {
        message: h('div', null, newPromptMessages),
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true,
        type: 'warning'
      }).then(() => {
        //发送删除请求
        this.deleteRequest('/authorize/mobile-office/' + row.id).then(resp => {
          this.sendRequest();
        });
      }).catch(() => {
        console.log('取消删除')
      })
    },
    changeBelongType(selValue) {
      if(selValue == 'HEAD') {

      } else {
        this.editForm.siteList = [];
      }
    },
    handleSizeChange(val) {
      this.pagination.pageSize = val;
      this.sendRequest();
    },
    handleCurrentChange(val) {
      this.pagination.pageNum = val;
      this.sendRequest();
    },
    dialogHandleSizeChange(val) {
      this.dialogPagination.pageSize = val;
    },
    dialogHandleCurrentChange(val) {
      this.dialogPagination.pageNum = val;
    },
    tableRowClassName({row, rowIndex}) {
      if (rowIndex % 2 === 1) {
        return 'row-bg-color';
      } else {
        return ''
      }
    },
    clearForm() {
      //清空提交表单内容
      this.editForm.id = '',
      this.editForm.name = '',
      this.editForm.belongType = '',
      this.editForm.baseBelongType = '',
      this.editForm.roleList = [],
      this.editForm.siteList = []
      this.branchSelVal = '';
      this.selSiteList = [];
      this.belongList = [];
      this.search = '';
    },
    dialogCancel() {
      //点击取消按钮事件
      this.dialogFormVisible = false;
      this.clearForm();
    },
    closeDialog() {
      //关闭弹框时事件
      this.dialogCancel();
    },
    openInnerDialog() {
      this.innerVisible = true;
    },
    siteSelChange(selection) {
      if(selection && selection.length > 0) {
        let curPageSel = selection;
        curPageSel.forEach(value => {
          if(this.selSiteList.filter(data => data.id == value.id).length == 0) {
            this.selSiteList.push(value);
          }
        })
      }
      this.$refs.siteTable.clearSelection();
    },
    deleteSelSiteRow(index, rows) {
      this.selSiteList.splice(index, 1);
    }
  },
  data() {
    return {
      recordTableSelect: true,
      loading: true,
      tableSize: 'medium',
      maxHeight: 500,
      rowHeight: 36,
      branchSelVal: '',
      tableData: [],
      pagination: {
        pageSize: 10,
        pageNum: 1,
        total: 0
      },
      dialogPagination: {
        pageSize: 50,
        pageNum: 1,
        total: 0
      },
      belongTypeList: [
        {value: 'HEAD', label: '总部'},
        {value: 'BRANCH', label: '分公司'},
        {value: 'SITE', label: '网点'}
      ],
      belongList: [],
      siteList: [],
      selSiteList: [],
      editForm: {
        id: '',
        name: '',
        belongType: '',
        baseBelongType: '',
        roleList: [],
        siteList:[]
      },
      roleData: [],
      formLabelWidth: '75px',
      formSize: 'small',
      dialogFormVisible: false,
      innerVisible: false,
      search: ''
    }
  },
  computed: {
    handleDialogPagination() {
      let pageNum = this.dialogPagination.pageNum;
      let pageSize = this.dialogPagination.pageSize;
      let data = this.siteList.filter(data => !this.search || data.name.includes(this.search)
          || data.type.toLowerCase().includes(this.search.toLowerCase())
          || data.address.includes(this.search)
          || data.websitNumber.toLowerCase().includes(this.search.toLowerCase()));
      this.dialogPagination.total = data.length;
      data = data.slice((pageNum-1)*pageSize, pageNum*pageSize);

      return data;
    }
  },
  mounted() {
    //vue加载完成后发送列表请求
    this.sendRequest();
    //查询分公司请求
    this.getRequest('/common-query/mobile/belong').then(resp => {
      if(resp) {
        this.belongList = resp;
      }
    });
    // this.getRequest('/common-query/mobile/site').then(resp => {
    //   if(resp) {
    //     this.siteList = resp;
    //   }
    // })
    this.siteList = this.$store.state.sites;
  }
}
</script>

<style>
.el-table .row-bg-color {
  background: #eef1f6;
}
</style>
