<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="6">
        <el-input
            placeholder="请输入手机号"
            v-model="pagination.phone"
            clearable>
        </el-input>
      </el-col>
      <el-col :span="6">
        <el-input
            placeholder="请输入昵称"
            v-model="pagination.nickname"
            clearable>
        </el-input>
      </el-col>
      <el-col :span="6">
        <el-button type="primary" style="margin-bottom: 15px;" class="el-icon-eleme" @click="sendRequest">
          查询
        </el-button>
      </el-col>
    </el-row>
    <el-table
        v-loading="loading"
        :data="tableData"
        style="width: 100%"
        :size="tableSize"
        :max-height="maxHeight"
        :row-class-name="tableRowClassName"
        :header-cell-style="{background:'#eef1f6',color:'#606266'}"
        fit>
      <el-table-column
          fixed
          prop="openid"
          label="openid"
          min-width="180">
      </el-table-column>
      <el-table-column
          prop="nickname"
          label="昵称"
          min-width="100">
      </el-table-column>
      <el-table-column
          prop="avatarUrl"
          label="头像"
          min-width="80">
        <template slot-scope="scope">
          <img :src="scope.row.avatarUrl" min-width="40" height="40"/>
        </template>
      </el-table-column>
      <el-table-column
          prop="phone"
          label="手机号"
          min-width="120">
      </el-table-column>
      <el-table-column
          prop="createTime"
          label="申请时间"
          min-width="180">
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          min-width="120">
        <template slot-scope="scope">
          <el-tooltip content="审核" placement="left-start" effect="light">
            <el-button
                @click.native.prevent="confirmRow(scope.$index, tableData)"
                icon="el-icon-check"
                size="mini"
                type="primary">
            </el-button>
          </el-tooltip>
          <el-tooltip content="删除" placement="top-end" effect="light">
            <el-button
                @click.native.prevent="deleteRow(scope.$index, tableData)"
                icon="el-icon-delete"
                size="mini"
                type="danger">
            </el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination" style="text-align: right;">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 15, 20]"
          :page-size="pagination.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pagination.total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "AuthorizeMobileOfficeDeclare",
  data() {
    return {
      loading: true,
      tableSize: 'medium',
      maxHeight: 500,
      tableData: [],
      pagination: {
        phone: '',
        nickname: '',
        pageSize: 10,
        pageNum: 1,
        total: 0
      }
    }
  },
  computed: {
  },
  mounted() {
    this.sendRequest();
  },
  methods: {
    sendRequest() {
      this.loading = true;
      let params = {
        phone: this.pagination.phone,
        nickname: this.pagination.nickname,
        pageSize: this.pagination.pageSize,
        pageNum: this.pagination.pageNum
      }
      this.getRequest('/authorize/mobile-office-declare/', params).then((resp)=> {
        if(resp) {
          this.tableData = resp.data.records;
          this.pagination.total = resp.data.total;
        }
        this.loading = false;
      }, () => {this.loading = false;})
    },
    confirmRow(index, rows) {
      let row = rows[index];
      let promptMessages = ['确认审核信息:', `openid: ${row.openid}`, `昵称: ${row.nickname}`, `手机号: ${row.phone}`]
      const newPromptMessages = []
      const h = this.$createElement
      for (const i in promptMessages) {
        newPromptMessages.push(h('p', null, promptMessages[i]))
      }
      //审核openid
      this.$confirm('提示', {
        message: h('div', null, newPromptMessages),
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true,
        type: 'warning'
      }).then(() => {
        //发送审核请求
        this.putRequest('/authorize/mobile-office-declare/confirm/' + row.id);
        setTimeout(() => {
          this.sendRequest();
        }, 1000);

      }).catch(() => {
        console.log('取消审核')
      })
    },
    deleteRow(index, rows) {
      let row = rows[index];
      let promptMessages = ['确认删除信息:', `openid: ${row.openid}`, `昵称: ${row.nickname}`, `手机号: ${row.phone}`]
      const newPromptMessages = []
      const h = this.$createElement
      for (const i in promptMessages) {
        newPromptMessages.push(h('p', null, promptMessages[i]))
      }
      //删除openid
      this.$confirm('提示', {
        message: h('div', null, newPromptMessages),
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true,
        type: 'warning'
      }).then(() => {
        //发送删除请求
        this.deleteRequest('/authorize/mobile-office-declare/' + row.id);
        this.sendRequest();
      }).catch(() => {
        console.log('取消删除')
      })
    },
    handleSizeChange(val) {
      this.pagination.pageSize = val;
      this.sendRequest();
    },
    handleCurrentChange(val) {
      this.pagination.pageNum = val;
      this.sendRequest();
    },
    tableRowClassName({row, rowIndex}) {
      if (rowIndex % 2 === 1) {
        return 'row-bg-color';
      } else {
        return ''
      }
    }
  }
}

</script>

<style>
.el-table .row-bg-color {
  background: #eef1f6;
}
</style>
